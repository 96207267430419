import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import CreatableSelect from 'react-select/creatable';
import { startSetUserInfo } from '../../actions/user';

export class IncomeForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIncomeType: props.income ? getOptionObject(props.income.type) : null,
      note: props.income ? props.income.note : '',
      amount: props.income ? (props.income.amount / 100).toString() : '',
      createdAt: props.income ? moment(props.income.createdAt) : moment(),
      calenderFocused: false,
      errorIncomeType: '',
      errorAmount: ''
    };

    this.incomeTypeRef = React.createRef();
    this.amountRef = React.createRef();
  };

  componentDidMount() {
    console.log('componentDidMount> ', this.props.incomeType)
  }

  onDescChange = (e) => {
    const desc = e.target.value;
    this.setState({ desc });
  };

  onAmountChange = (e) => {
    const amount = e.target.value;
    if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
      this.setState({ amount });
      this.setState({ errorAmount: '' });
    }
  };

  onNoteChange = (e) => {
    const note = e.target.value;
    this.setState({ note })
  };

  onDateChange = (createdAt) => {
    if (createdAt) {
      this.setState({ createdAt });
    }
  };

  onFocusChange = ({ focused }) => {
    this.setState({ calenderFocused: focused })
  };

  handleIncomeTypeChange = (selectedIncomeType) => {
    this.setState({ selectedIncomeType });
    console.log('handleIncomeTypeChange> selectedType:', selectedIncomeType);
    this.setState({ errorIncomeType: '' });
  };

  handleIncomeTypeCreation = (newType) => {
    console.log('handleIncomeTypeCreation> newType:', newType);
    this.props.startSetUserInfo({
      incomeType: [
        newType,
        ...this.props.incomeType
      ]
    });

    this.setState({ selectedIncomeType: getOptionObject(newType)});
    this.setState({ errorIncomeType: '' });
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (!this.state.selectedIncomeType) {
      this.setState({ errorIncomeType: 'Please enter Income type' });
      return this.incomeTypeRef.current.focus();
    }
    if (!this.state.amount) {
      this.setState({ errorAmount: 'Please enter an amount' });
      return this.amountRef.current.focus();
    }

    this.props.onSubmit({
      type: this.state.selectedIncomeType.label,
      amount: parseFloat(this.state.amount, 10) * 100,
      createdAt: this.state.createdAt.valueOf(),
      note: this.state.note? this.state.note : undefined
    });
  };

  render() {
    return (
      <form className="form" onSubmit={this.onSubmit}>
        {this.state.errorAmount && <p className="form__error--inline">{this.state.errorAmount}</p>}
        {(!this.state.errorAmount && this.state.amount) && <p className="form__label">Amount</p>}
        <input
          type="text"
          placeholder="Amount"
          className="text-input"
          value={this.state.amount}
          onChange={this.onAmountChange}
          autoFocus
          ref={this.amountRef}
        />
        {this.state.errorIncomeType && <p className="form__error--inline">{this.state.errorIncomeType}</p>}
        {(!this.state.errorIncomeType && this.state.selectedIncomeType) && <p className="form__label">Type</p>}
        <CreatableSelect
          styles={{
            control: (base) => ({
              ...base,
              height: 50,
              fontSize: 18,
              fontWeigth: 300,
              borderRadius: 0
          })}}          
          value={this.state.selectedIncomeType}
          options={this.props.incomeTypeOptions}
          onChange={this.handleIncomeTypeChange}
          onCreateOption={this.handleIncomeTypeCreation}
          placeholder="Select type of Income"
          ref={this.incomeTypeRef}
        />
        {this.state.createdAt && <p className="form__label">Date</p>}
        <SingleDatePicker
          date={this.state.createdAt}
          onDateChange={this.onDateChange}
          focused={this.state.calenderFocused}
          onFocusChange={this.onFocusChange}
          numberOfMonths={1}
          isOutsideRange={() => false}
        />
        {this.state.note && <p className="form__label">Note</p>}
        <textarea
          placeholder="Add a note for your income (optional)"
          className="textarea"
          value={this.state.note}
          onChange={this.onNoteChange}
        >
        </textarea>
        <div>
          <button className="button">Save Income</button>
        </div>
      </form>
    );
  }
};

const getOptionObject = (option) => ({
  value: option.toLowerCase(),
  label: option
});

const mapStateToProps = (state) => ({
  incomeType: state.user.incomeType,
  incomeTypeOptions: state.user.incomeType.map(type => getOptionObject(type)),
});

const mapDispatchToProps = (dispatch) => ({
  startSetUserInfo: (update) => dispatch(startSetUserInfo(update))
});

export default connect(mapStateToProps, mapDispatchToProps)(IncomeForm);