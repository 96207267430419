import React from 'react';
import { connect } from 'react-redux';
import ExpenseList from './ExpenseList';
import ExpenseListFilters from './ExpenseListFilters';
import ExpensesSummary from './ExpensesSummary';
import LoadingPage from '../LoadingPage';
import { startGetExpenses } from '../../actions/expenses';

class ExpenseDashboardPage extends React.Component {
  componentDidMount() {
    // console.log('componentDidMount> this.props.expenses:', this.props.expenses);
    if (!this.props.expenses) {
      console.log('this.props.expenses> startGetExpenses')
      this.props.startGetExpenses(
        this.props.filters.startDate.valueOf(),
        this.props.filters.endDate.valueOf()).then(() => {

      });
    }
  }

  render() {
    return (
      <div>
        {!this.props.expenses? (
            <LoadingPage />
          ):(
            <div>
              <ExpensesSummary />
              <ExpenseListFilters />
              <ExpenseList />
            </div>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  expenses: state.expenses,
  filters: state.incomeFilters
});

const mapDispatchToProps = (dispatch) => ({
  startGetExpenses: (startDate, endDate) => dispatch(startGetExpenses(startDate, endDate))
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseDashboardPage);
