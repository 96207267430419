import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import LoginPage from '../components/LoginPage';
import SignUpPage from '../components/SignUpPage';
import ExpenseDashboardPage from '../components/expense/ExpenseDashboardPage';
import AddExpensePage from '../components/expense/AddExpensePage';
import EditExpensePage from '../components/expense/EditExpensePage';
import IncomeDashboardPage from '../components/income/IncomeDashboardPage';
import AddIncomePage from '../components/income/AddIncomePage';
import EditIncomePage from '../components/income/EditIncomePage';
import NotFoundPage from '../components/NotFoundPage';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

export const history = createBrowserHistory();

const AppRouter = () => (
  <Router history={history}>
    <div>
      <Switch>
        <PublicRoute path="/" component={LoginPage} exact={true} />
        <PrivateRoute path="/expense/dashboard" component={ExpenseDashboardPage} />
        <PrivateRoute path="/expense/create" component={AddExpensePage} />
        <PrivateRoute path="/expense/edit/:id" component={EditExpensePage} />
        <PrivateRoute path="/income/dashboard" component={IncomeDashboardPage} />
        <PrivateRoute path="/income/create" component={AddIncomePage} />
        <PrivateRoute path="/income/edit/:id" component={EditIncomePage} />
        <PublicRoute path="/signup" component={SignUpPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </div>
  </Router>
);

export default AppRouter;