import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ExpenseForm from './ExpenseForm';
import { startEditExpense, startRemoveExpense } from '../../actions/expenses';
import ConfirmRemovalModal from './ConfirmRemovalModal';

export class EditExpensePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfirmRemovalModal: false
    };
  };

  onSubmit = (expense) => {
    this.props.startEditExpense(this.props.expense.id, expense);
    this.props.history.push('/');
  };

  onRemove = () => {
    this.setState(() => ({ showConfirmRemovalModal: true }));
  };

  handleConfirmRemoval = () => {
    this.props.startRemoveExpense({ id: this.props.expense.id });
    this.props.history.push('/');
  };

  handleCancelRemoval = () => {
    this.setState(() => ({ showConfirmRemovalModal: false }));
  };

  render() {
    return (
      <div>
        {this.props.expense &&
          <div>
            <div className="page-header">
              <div className="content-container">
                <h1 className="page-header__title">Edit Expense</h1>
              </div>
            </div>
            <div className="content-container">
              <ExpenseForm
                expense={this.props.expense}
                onSubmit={this.onSubmit}
              />
              <button
                className="button button--secondary"
                onClick={this.onRemove}>Remove Expense
              </button>  
            </div>
            <ConfirmRemovalModal
              expense={this.props.expense}
              showConfirmRemovalModal={this.state.showConfirmRemovalModal}
              handleConfirmRemoval={this.handleConfirmRemoval}
              handleCancelRemoval={this.handleCancelRemoval}
            />          
          </div>
        }
      </div>
    );
  }
};

const mapStateToProps = (state, props) => ({
  expense: state.expenses.find((expense) => expense.id === props.match.params.id)
});

const mapDispatchToProps = (dispatch, props) => ({
  startEditExpense: (id, expense) => dispatch(startEditExpense(id, expense)),
  startRemoveExpense: (data) => dispatch(startRemoveExpense(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditExpensePage);
