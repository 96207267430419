import moment from 'moment';

export default (expenses, { text, excludeType, sortBy, startDate, endDate }) => {
  return expenses.filter((expense) => {
    const createdAtMoment = moment(expense.createdAt);
    const startDateMatch = startDate ? startDate.isSameOrBefore(createdAtMoment, 'day') : true;
    const endDateMatch = endDate ? endDate.isSameOrAfter(createdAtMoment, 'day') : true;

    const expenseTypeLowerCase = expense.type.toLowerCase();

    let textMatch = true;
    if (typeof text === 'string') {
      const textLowerCase = text.trim().toLowerCase();
      if (textLowerCase.length !== 0) {
        const typeMatch = expenseTypeLowerCase.includes(textLowerCase);
        const paymentMatch = expense.payment.toLowerCase().includes(textLowerCase);
        const noteMatch = expense.note ? expense.note.toLowerCase().includes(textLowerCase) : false;
        const amountMatch = (expense.amount / 100).toString().includes(textLowerCase);
        textMatch =  typeMatch || paymentMatch || noteMatch || amountMatch;
      }
    }

    let excludedTypeMatch = false;
    if (typeof excludeType === 'string') {
      const excludeTypeTrimLowerCase = excludeType.trim().toLowerCase();
      if (excludeTypeTrimLowerCase.length !== 0) {
        excludedTypeMatch = expenseTypeLowerCase.includes(excludeTypeTrimLowerCase);
      }
    }

    return !excludedTypeMatch && textMatch && startDateMatch && endDateMatch;
  }).sort((a, b) => {
    if (sortBy === 'date') {
      return a.createdAt < b.createdAt ? 1 : -1;
    }
    else if(sortBy === 'amount') {
      return a.amount < b.amount ? 1 : -1;
    }
  });
};