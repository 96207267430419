import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AppRouter, { history } from './routers/AppRouter';
import configureStore from './store/configureStore';
import LoadingPage from './components/LoadingPage';
import { logout } from './actions/auth';
import { startGetExpenses } from './actions/expenses';
import { init } from './localStorage/utils';
import 'normalize.css/normalize.css';
import './styles/styles.scss';
import 'react-dates/lib/css/_datepicker.css';

const store = configureStore();

const jsx = (
  <Provider store={store}>
    <AppRouter />
  </Provider>
);

let hasRendered = false;
const renderApp = () => {
  if (!hasRendered) {
    ReactDOM.render(jsx, document.getElementById('app'));
    hasRendered = true;
  }
};

ReactDOM.render(<LoadingPage />, document.getElementById('app'));

init(store, (user) => {
  if (user) {
    console.log('app> login in => user');
    renderApp();
    history.push('/expense/dashboard');
    // store.dispatch(startGetExpenses()).then(() => {
    //   console.log('app> startGetExpenses OK');
    //   renderApp();
    //   if (history.location.pathname === '/') {
    //     history.push('/expense/dashboard');
    //   }
    // })
    // .catch((e) => {
    //   console.log('app> failed to get all expenses', e);
    // });
  }
  else {
    console.log('app> not login');
    store.dispatch(logout());
    renderApp();
    if (history.location.pathname !== '/signup') {
      history.push('/');
    }
  }
});
