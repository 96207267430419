import React from 'react';
import { connect } from 'react-redux';
import IncomeList from './IncomeList';
import IncomeListFilters from './IncomeListFilters';
import IncomesSummary from './IncomesSummary';
import LoadingPage from '../LoadingPage';
import { startGetIncomes } from '../../actions/incomes';

class IncomeDashboardPage extends React.Component {
  componentDidMount() {
    // console.log('componentDidMount> this.props.incomes:', this.props.incomes);
    if (!this.props.incomes) {
      console.log('this.props.incomes> startGetIncomes')
      this.props.startGetIncomes(
        this.props.filters.startDate.valueOf(),
        this.props.filters.endDate.valueOf()).then(() => {

      });
    }
  }

  render() {
    return (
      <div>
        {!this.props.incomes ? (
            <LoadingPage />
          ):(
            <div>
              <IncomesSummary />
              <IncomeListFilters />
              <IncomeList />
            </div>
          )
        }
      </div>  
    );
  }
}

const mapStateToProps = (state) => ({
  incomes: state.incomes,
  filters: state.expenseFilters
});

const mapDispatchToProps = (dispatch) => ({
  startGetIncomes: (startDate, endDate) => dispatch(startGetIncomes(startDate, endDate))
});

export default connect(mapStateToProps, mapDispatchToProps)(IncomeDashboardPage);
