import React from 'react';
import { connect } from 'react-redux';
import IncomeItem from './IncomeItem';
import selectIncomes from '../../selectors/incomes';

const IncomeList = (props) => (
  <div className="content-container">
    <div className="list-header">
      <div className="show-for-mobile">Incomes</div>
      <div className="show-for-desktop">Income</div>
      <div className="show-for-desktop">Amount</div>
    </div>
    <div className="list-body">
      {props.incomes.length === 0 ? (
        <div className="list-item list-item--message">
          <p>No incomes</p>
        </div>
      ) : (
        props.incomes.map((income) => (
          <IncomeItem key={income.id} {...income} />
        ))
      )}
    </div>    
  </div>
);

const mapStateToProps = (state) => ({
  incomes: selectIncomes(state.incomes, state.incomeFilters)
});

export default connect(mapStateToProps)(IncomeList);