import React from 'react';
import { connect } from 'react-redux';
import { startLogin, setStatusCode } from '../actions/auth';

export class SignUpPage extends React.Component {
  getError = (statusCode) => {
    if (statusCode === 400) {
      return 'The email or password is incorrect'
    }
    else if (statusCode == 401) {
      return 'Access denied';
    }
    else {
      return `Login failed: ${statusCode}`;
    }
  };

  onChange = () => {
    this.props.setStatusCode(undefined);
  };
    
  render() {
    return (
      <div className="box-layout">
        <div className="box-layout__box">
          <h1 className="box-layout__title">Thrifti</h1>
          <p className="box-layout__subtitle">Is a virtue!</p>
          {this.props.statusCode && <p className="form__error">{this.getError(this.props.statusCode)}</p>}
          <form className="form" onSubmit={(e) => {
            e.preventDefault();
            const email = e.target.elements.email.value;
            const password = e.target.elements.password.value;
            this.props.startLogin(email, password);
          }}>
            <input
              className="text-input"
              type="email"
              name="email"
              placeholder="Email"
              onChange={this.onChange}
              required
              autoFocus />
            <input
              className="text-input"
              type="password"
              name="password"
              placeholder="Password"
              onChange={this.onChange}
              required
              minLength="8" />
            <input
              className="text-input"
              type="password"
              name="confirmPassword"
              placeholder="Re-enter Password"
              onChange={this.onChange}
              required
              minLength="8" />
            <button
              className="button"
            >
              Sign up
            </button>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  statusCode: state.auth.statusCode
});

const mapDispatchToProps = (dispatch) => ({
  startLogin: (email, password) => dispatch(startLogin(email, password)),
  setStatusCode: (statusCode) => dispatch(setStatusCode(statusCode))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage);