import React from 'react';
import Modal from 'react-modal';
import numeral from 'numeral';

Modal.setAppElement(document.getElementById('app'));

const ConfirmRemovalModal = (props) => (
  <Modal
    isOpen={props.showConfirmRemovalModal}
    onRequestClose={props.handleCancelRemoval}
    contentLabel="Delete Income"
    closeTimeoutMS={200}
    className="modal"
  >
    <h3 className="modal__title">Delete Income</h3>
    <p className="modal__body">{props.income.desc} {numeral(props.income.amount / 100).format('$0,0.00')} ?</p>
    <button className="button button--secondary button--margin-right" onClick={props.handleCancelRemoval}>Cancel</button>
    <button className="button" onClick={props.handleConfirmRemoval}>Delete</button>
  </Modal>
);

export default ConfirmRemovalModal;