import moment from 'moment';

export default (incomes, { text, sortBy, startDate, endDate }) => {
  return incomes.filter((income) => {
    const createdAtMoment = moment(income.createdAt);
    const startDateMatch = startDate ? startDate.isSameOrBefore(createdAtMoment, 'day') : true;
    const endDateMatch = endDate ? endDate.isSameOrAfter(createdAtMoment, 'day') : true;

    let textMatch = true;
    if (typeof text === 'string') {
      const textLowerCase = text.trim().toLowerCase();
      const typeMatch = income.type.toLowerCase().includes(textLowerCase);
      const noteMatch = income.note ? income.note.toLowerCase().includes(textLowerCase) : false;
      const amountMatch = (income.amount / 100).toString().includes(textLowerCase);
      textMatch = typeMatch || noteMatch || amountMatch;
    }
    
    return textMatch && startDateMatch && endDateMatch;
  }).sort((a, b) => {
    if (sortBy === 'date') {
      return a.createdAt < b.createdAt ? 1 : -1;
    }
    else if(sortBy === 'amount') {
      return a.amount < b.amount ? 1 : -1;
    }
  });
};