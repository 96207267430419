import axios from 'axios';
import { history } from '../routers/AppRouter';
import { startGetUserInfo } from '../actions/user';
import { startGetExpenses } from '../actions/expenses';
import { setCredentials } from '../localStorage/utils';

export const login = (token, email) => ({
  type: 'LOGIN',
  token,
  email
});

export const startLogin = (email, password) => {
  return (dispatch) => {
    return axios.post('/users/login', {
      email,
      password
    }, {
      // WORKAROUND: the appid is saved in the name attr of the loginForm as I can't find
      // a way to passed in the appid (a template string) into JS
      headers: { 'x-appid': 'TODO' }
    })
    .then((res) => {
      console.log('startLogin> login OK');
      const token = res.headers['x-auth'];

      // cache it
      setCredentials({ token, email });
      dispatch(login(token, email));

      // get user info
      return dispatch(startGetUserInfo()).then(() => {
        return dispatch(startGetExpenses()).then(() => {
          console.log('startLogin> startGetExpenses OK');
        })
        .catch((e) => {
          console.log('startLogin> startGetExpenses failed:', e);
          throw e;
        });
      })
      .catch((e) => {
        console.log('startLogin> startGetUserInfo failed:', e);
        throw e;
      });
    })
    .catch((e) => {
      console.log('startLogin> overall failed:', e);
      throw e;
    });    
  };
};

export const logout = () => ({
  type: 'LOGOUT'
});

export const cleanUpAndRedirect = (dispatch) => {
  setCredentials();
  dispatch(logout());
  history.push('/');
};

export const startLogout = () => {
  return (dispatch, getState) => {
    // console.log('token', getState().auth.token);

    return axios.delete('/users/me/token', {
      headers: { 'x-auth': getState().auth.token }
    })
    .then((res) => {
      console.log('startLogout> logout OK');
      return cleanUpAndRedirect(dispatch);
    })
    .catch((e) => {
      console.log('startLogout> logout failed:', e);
      cleanUpAndRedirect(dispatch);
      throw e;
    });
  };
};

