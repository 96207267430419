import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import CreatableSelect from 'react-select/creatable';
import { startSetUserInfo } from '../../actions/user';

export class ExpenseForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedExpenseType: props.expense ? getOptionObject(props.expense.type) : null,
      selectedPaymentType: props.expense ? getOptionObject(props.expense.payment) : null,
      desc: props.expense ? props.expense.desc : '',
      note: props.expense ? props.expense.note : '',
      amount: props.expense ? (props.expense.amount / 100).toString() : '',
      createdAt: props.expense ? moment(props.expense.createdAt) : moment(),
      calenderFocused: false,
      errorExpenseType: '',
      errorPaymentType: '',
      errorAmount: ''
    };

    this.expenseTypeRef = React.createRef();
    this.paymentTypeRef = React.createRef();
    this.amountRef = React.createRef();
  };

  onDescChange = (e) => {
    const desc = e.target.value;
    this.setState({ desc });
  };

  onAmountChange = (e) => {
    const amount = e.target.value;
    if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
      this.setState({ amount });
      this.setState({ errorAmount: '' });
    }
  };

  onNoteChange = (e) => {
    const note = e.target.value;
    this.setState({ note })
  };

  onDateChange = (createdAt) => {
    if (createdAt) {
      this.setState({ createdAt });
    }
  };

  onFocusChange = ({ focused }) => {
    this.setState({ calenderFocused: focused })
  };

  handleExpenseTypeChange = (selectedExpenseType) => {
    this.setState({ selectedExpenseType });
    console.log('handleExpenseTypeChange> selectedType:', selectedExpenseType);
    this.setState({ errorExpenseType: '' });
  };

  handleExpenseTypeCreation = (newType) => {
    console.log('handleExpenseTypeCreation> newType:', newType);
    this.props.startSetUserInfo({
      expenseType: [
        newType,
        ...this.props.expenseType
      ]
    });

    this.setState({ selectedExpenseType: getOptionObject(newType)});
    this.setState({ errorExpenseType: '' });
  };

  handlePaymentTypeChange = (selectedPaymentType) => {
    this.setState({ selectedPaymentType });
    console.log('selectedPaymentType:', selectedPaymentType);
    this.setState({ errorPaymentType: '' });
  };

  handlePaymentTypeCreation = (newType) => {
    console.log('handlePaymentTypeCreation> newType:', newType);
    this.props.startSetUserInfo({
      paymentType: [
        newType,
        ...this.props.paymentType
      ]
    });

    this.setState({ selectedPaymentType: getOptionObject(newType)});
    this.setState({ errorPaymentType: '' });
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (!this.state.selectedExpenseType) {
      this.setState({ errorExpenseType: 'Please enter expense type' });
      return this.expenseTypeRef.current.focus();
    }
    if (!this.state.selectedPaymentType) {
      this.setState({ errorPaymentType: 'Please enter payment type' });
      return this.paymentTypeRef.current.focus();
    }
    if (!this.state.amount) {
      this.setState({ errorAmount: 'Please enter an amount' });
      return this.amountRef.current.focus();
    }

    this.props.onSubmit({
      type: this.state.selectedExpenseType.label,
      payment: this.state.selectedPaymentType.label,
      amount: parseFloat(this.state.amount, 10) * 100,
      createdAt: this.state.createdAt.valueOf(),
      note: this.state.note? this.state.note : undefined
    });
  };

  render() {
    return (
      <form className="form" onSubmit={this.onSubmit}>
        {this.state.errorAmount && <p className="form__error--inline">{this.state.errorAmount}</p>}
        {(!this.state.errorAmount && this.state.amount) && <p className="form__label">Amount</p>}
        <input
          type="text"
          placeholder="Amount"
          className="text-input"
          value={this.state.amount}
          onChange={this.onAmountChange}
          autoFocus
          ref={this.amountRef}
        />
        {this.state.errorExpenseType && <p className="form__error--inline">{this.state.errorExpenseType}</p>}
        {(!this.state.errorExpenseType && this.state.selectedExpenseType) && <p className="form__label">Type</p>}
        <CreatableSelect
          styles={{
            control: (base) => ({
              ...base,
              height: 50,
              fontSize: 18,
              fontWeigth: 300,
              borderRadius: 0
          })}}          
          value={this.state.selectedExpenseType}
          options={this.props.expenseTypeOptions}
          onChange={this.handleExpenseTypeChange}
          onCreateOption={this.handleExpenseTypeCreation}
          placeholder="Select type of expense"
          ref={this.expenseTypeRef}
        />
        {this.state.errorPaymentType && <p className="form__error--inline">{this.state.errorPaymentType}</p>}
        {(!this.state.errorPaymentType && this.state.selectedPaymentType) && <p className="form__label">Payment mode</p>}
        <CreatableSelect
          styles={{
            control: (base) => ({
              ...base,
              height: 50,
              fontSize: 18,
              fontWeigth: 300,
              borderRadius: 0
          })}}          
          value={this.state.selectedPaymentType}
          options={this.props.paymentTypeOptions}
          onChange={this.handlePaymentTypeChange}
          onCreateOption={this.handlePaymentTypeCreation}
          placeholder="Select mode of payment"
          ref={this.paymentTypeRef}
        />
        {this.state.createdAt && <p className="form__label">Date</p>}
        <SingleDatePicker
          date={this.state.createdAt}
          onDateChange={this.onDateChange}
          focused={this.state.calenderFocused}
          onFocusChange={this.onFocusChange}
          numberOfMonths={1}
          isOutsideRange={() => false}
        />
        {this.state.note && <p className="form__label">Note</p>}
        <textarea
          placeholder="Add a note for your expense (optional)"
          className="textarea"
          value={this.state.note}
          onChange={this.onNoteChange}
        >
        </textarea>
        <div>
          <button className="button">Save Expense</button>
        </div>
      </form>
    );
  }
};

const getOptionObject = (option) => ({
  value: option.toLowerCase(),
  label: option
});

const mapStateToProps = (state) => ({
  expenseType: state.user.expenseType,
  expenseTypeOptions: state.user.expenseType.map(type => getOptionObject(type)),
  paymentType: state.user.paymentType,
  paymentTypeOptions: state.user.paymentType.map(type => getOptionObject(type)),
});

const mapDispatchToProps = (dispatch) => ({
  startSetUserInfo: (update) => dispatch(startSetUserInfo(update))
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseForm);