export default (state = {}, action) => {
  switch (action.type) {
    case 'GET_USERINFO':
      return {
        ...state,
        email: action.email,
        verified: action.verified,
        expenseType: action.expenseType,
        paymentType: action.paymentType,
        incomeType: action.incomeType
      };
      
    case 'SET_USERINFO':
      return {
        ...state,
        expenseType: action.expenseType,
        paymentType: action.paymentType,
        incomeType: action.incomeType
      };
  
    default:
      return state;
  }
};