import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import numeral from 'numeral';

const IncomeItem = ({ id, createdAt, amount, type, payment, note }) => (
  <Link className="list-item" to={`/income/edit/${id}`}>
    <div>
      <h3 className="list-item__title">{ type }</h3>
      <span className="list-item__subtitle">{ moment(createdAt).format('ddd D MMM, YYYY') }</span>
      <div className="list-item__subtitle">{ note }</div>
    </div>
    <div className="list-item__data-group">
      <h3 className="list-item__data">{ numeral(amount / 100).format('$0,0.00') }</h3>
      <span className="list-item__subtitle">{ payment }</span>
    </div>
  </Link>
);

export default IncomeItem;