import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import authReducer from '../reducers/auth';
import userReducer from '../reducers/user';
import expensesReducer from '../reducers/expenses';
import expenseFiltersReducer from '../reducers/expenseFilters';
import incomesReducer from '../reducers/incomes';
import incomeFiltersReducer from '../reducers/incomeFilters';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
  const store = createStore(
    combineReducers({
      auth: authReducer,
      user: userReducer,
      expenses: expensesReducer,
      expenseFilters: expenseFiltersReducer,
      incomes: incomesReducer,
      incomeFilters: incomeFiltersReducer
    }),
    composeEnhancers(applyMiddleware(thunk))
  );
  
  return store;
};
