import React from 'react';
import { connect } from 'react-redux';
import { startLogin, setStatusCode } from '../actions/auth';

export class LoginPage extends React.Component {
  state = {
    errorInfo: '',
    errorStatus: 0
  };

  getError = () => {
    if (this.state.errorStatus === 400) {
      return 'The email or password is incorrect'
    }
    else {
      return `${this.state.errorInfo}`;
    }
  };

  onChange = () => {
    this.setState({
      errorInfo: '',
      errorStatus: 0
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const email = e.target.elements.email.value;
    const password = e.target.elements.password.value;
    this.props.startLogin(email, password).then(() => {
      this.props.history.push('/expense/dashboard');
    })
    .catch((e) => {
      console.log('LoginPage> e', e)
      this.setState({
        errorInfo: e.response.data.error ? e.response.data.error.info : e.response.statusText,
        errorStatus: e.response.status
      });
    });
  };
    
  render() {
    return (
      <div className="box-layout">
        <div className="box-layout__box">
          <h1 className="box-layout__title">Thrifti</h1>
          <p className="box-layout__subtitle">Is a virtue!</p>
          {this.state.errorInfo && <p className="form__error">{this.getError()}</p>}
          <form className="form" onSubmit={this.onSubmit}>
            <input
              className="text-input"
              type="email"
              name="email"
              placeholder="Email"
              onChange={this.onChange}
              required
              autoFocus />
            <input
              className="text-input"
              type="password"
              name="password"
              placeholder="Password"
              onChange={this.onChange}
              required
              minLength="8" />
            <button
              className="button"
            >
              Login
            </button>
          </form>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startLogin: (email, password) => dispatch(startLogin(email, password))
});

export default connect(null, mapDispatchToProps)(LoginPage);
