import axios from 'axios';

export const setIncomes = (incomes) => ({
  type: 'SET_INCOMES',
  incomes
});

export const startGetIncomes = (startDate, endDate) => {
  return (dispatch, getState) => {
    let query = '';
    if (startDate) {
      query = `${!query? '?':`${query}&`}startDate=${startDate}`;
    }
    if (endDate) {
      query = `${!query? '?':`${query}&`}endDate=${endDate}`;
    }

    return axios.get(`/incomes${query}`, {
      headers: { 'x-auth': getState().auth.token }
    })
    .then((res) => {
      if (res.status === 200) {
        // console.log('startGetIncomes>', res.data.results);
        return dispatch(setIncomes(res.data.results.incomes));
      }
    })
    .catch((e) => {
      console.log('startGetIncomes failed:', e);
      throw e;
    });
  };
};

export const addIncome = (income) => ({
  type: 'ADD_INCOME',
  income
});

export const startAddIncome = (incomeData = {}) => {
  return (dispatch, getState) => {
    const {
      type = '',
      note,
      amount = 0,
      createdAt = 0
    } = incomeData;

    return axios.post('/incomes', {
      type,
      amount,
      createdAt,
      note,
    }, {
      headers: { 'x-auth': getState().auth.token }
    })
    .then((res) => {
      if (res.status === 200) {
        console.log('startAddIncome> OK', res.data.results);
        return dispatch(addIncome(res.data.results.income));
      }
    })
    .catch((e) => {
      console.log('startAddIncome failed:', e);
      throw e;
    });
  };
};

export const removeIncome = ({ id } = {}) => ({
  type: 'REMOVE_INCOME',
  id
});

export const startRemoveIncome = ({ id } = {}) => {
  return (dispatch, getState) => {
    return axios.delete(`/incomes/${id}`, {
      headers: { 'x-auth': getState().auth.token }
    })
    .then((res) => {
      if (res.status === 200) {
        console.log('startRemoveIncome> OK', res.data.results);
        return dispatch(removeIncome({ id }));
      }
    })
    .catch((e) => {
      console.log('startRemoveIncome failed:', e);
      throw e;
    });
  }
};

export const editIncome = (id, updates) => ({
  type: 'EDIT_INCOME',
  id,
  updates
});

export const startEditIncome = (id, updates) => {
  return (dispatch, getState) => {
    return axios.put(`/incomes/${id}`, updates, {
      headers: { 'x-auth': getState().auth.token }
    })
    .then((res) => {
      if (res.status === 200) {
        console.log('startEditIncome> OK', res.data.results);
        return dispatch(editIncome(id, updates));
      }
    })
    .catch((e) => {
      console.log('startEditIncome failed:', e);
      throw e;
    });
  };
};