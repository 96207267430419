import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import IncomeForm from './IncomeForm';
import { startEditIncome, startRemoveIncome } from '../../actions/incomes';
import ConfirmRemovalModal from './ConfirmRemovalModal';

export class EditIncomePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfirmRemovalModal: false
    };
  };

  onSubmit = (income) => {
    this.props.startEditIncome(this.props.income.id, income);
    this.props.history.push('/income/dashboard');
  };

  onRemove = () => {
    this.setState(() => ({ showConfirmRemovalModal: true }));
  };

  handleConfirmRemoval = () => {
    this.props.startRemoveIncome({ id: this.props.income.id });
    this.props.history.push('/income/dashboard');
  };

  handleCancelRemoval = () => {
    this.setState(() => ({ showConfirmRemovalModal: false }));
  };

  render() {
    return (
      <div>
        {this.props.income &&
          <div>
            <div className="page-header">
              <div className="content-container">
                <h1 className="page-header__title">Edit Income</h1>
              </div>
            </div>
            <div className="content-container">
              <IncomeForm
                income={this.props.income}
                onSubmit={this.onSubmit}
              />
              <button
                className="button button--secondary"
                onClick={this.onRemove}>Remove Income
              </button>  
            </div>
            <ConfirmRemovalModal
              income={this.props.income}
              showConfirmRemovalModal={this.state.showConfirmRemovalModal}
              handleConfirmRemoval={this.handleConfirmRemoval}
              handleCancelRemoval={this.handleCancelRemoval}
            />          
          </div>
        }
      </div>
    );
  }
};

const mapStateToProps = (state, props) => ({
  income: state.incomes.find((income) => income.id === props.match.params.id)
});

const mapDispatchToProps = (dispatch, props) => ({
  startEditIncome: (id, income) => dispatch(startEditIncome(id, income)),
  startRemoveIncome: (data) => dispatch(startRemoveIncome(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditIncomePage);
