import moment from 'moment';

const filtersReducerDefaultState = {
  text: '',
  excludeType: '',
  sortBy: 'date',
  startDate: moment().startOf('month'),
  endDate: moment().endOf('month')
}

export default (state = filtersReducerDefaultState, action) => {
  switch(action.type) {
    case 'SET_EXPENSE_TEXT_FILTER':
      return {
        ...state,
        text: action.text
      }

    case 'SET_EXPENSE_EXCLUDE_TYPE_FILTER':
      return {
        ...state,
        excludeType: action.excludeType
      }
  
    case 'SORT_EXPENSE_BY_AMOUNT':
      return {
        ...state,
        sortBy: 'amount'
      }

    case 'SORT_EXPENSE_BY_DATE':
      return {
        ...state,
        sortBy: 'date'
      }

    case 'SET_EXPENSE_START_DATE':
      return {
        ...state,
        startDate: action.date
      }

    case 'SET_EXPENSE_END_DATE':
      return {
        ...state,
        endDate: action.date
      }
      
    default:
      return state;
  }
};