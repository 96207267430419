import axios from 'axios';

export const setExpenses = (expenses) => ({
  type: 'SET_EXPENSES',
  expenses
});

export const startGetExpenses = (startDate, endDate) => {
  return (dispatch, getState) => {
    let query = '';
    if (startDate) {
      query = `${!query? '?':`${query}&`}startDate=${startDate}`;
    }
    if (endDate) {
      query = `${!query? '?':`${query}&`}endDate=${endDate}`;
    }

    return axios.get(`/expenses${query}`, {
      headers: { 'x-auth': getState().auth.token }
    })
    .then((res) => {
      if (res.status === 200) {
        // console.log('startGetExpenses>', res.data.results);
        return dispatch(setExpenses(res.data.results.expenses));
      }
    })
    .catch((e) => {
      console.log('startGetExpenses failed:', e);
      throw e;
    });
  };
};

export const addExpense = (expense) => ({
  type: 'ADD_EXPENSE',
  expense
});

export const startAddExpense = (expenseData = {}) => {
  return (dispatch, getState) => {
    const {
      type = '',
      payment = '',
      note,
      amount = 0,
      createdAt = 0
    } = expenseData;
console.log('expenseData:', expenseData)
console.log(`note: '${note}'`)
    return axios.post('/expenses', {
      type,
      payment,
      amount,
      createdAt,
      note,
    }, {
      headers: { 'x-auth': getState().auth.token }
    })
    .then((res) => {
      if (res.status === 200) {
        console.log('startAddExpense> OK', res.data.results);
        return dispatch(addExpense(res.data.results.expense));
      }
    })
    .catch((e) => {
      console.log('startAddExpense failed:', e);
      throw e;
    });
  };
};

export const removeExpense = ({ id } = {}) => ({
  type: 'REMOVE_EXPENSE',
  id
});

export const startRemoveExpense = ({ id } = {}) => {
  return (dispatch, getState) => {
    return axios.delete(`/expenses/${id}`, {
      headers: { 'x-auth': getState().auth.token }
    })
    .then((res) => {
      if (res.status === 200) {
        console.log('startRemoveExpense> OK', res.data.results);
        return dispatch(removeExpense({ id }));
      }
    })
    .catch((e) => {
      console.log('startRemoveExpense failed:', e);
      throw e;
    });
  }
};

export const editExpense = (id, updates) => ({
  type: 'EDIT_EXPENSE',
  id,
  updates
});

export const startEditExpense = (id, updates) => {
  return (dispatch, getState) => {
    return axios.put(`/expenses/${id}`, updates, {
      headers: { 'x-auth': getState().auth.token }
    })
    .then((res) => {
      if (res.status === 200) {
        console.log('startEditExpense> OK', res.data.results);
        return dispatch(editExpense(id, updates));
      }
    })
    .catch((e) => {
      console.log('startEditExpense failed:', e);
      throw e;
    });
  };
};