import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import selectIncomes from '../../selectors/incomes';
import selectIncomesTotal from '../../selectors/incomesTotal';

export const IncomesSummary = ({incomeCount, incomesTotal}) => {
  const incomeWord = incomeCount === 1 ? 'income' : 'incomes';
  const formattedIncomesTotal = numeral(incomesTotal / 100).format('$0,0.00');

  return (
    <div className="page-header">
      <div className="content-container">
        <h1 className="page-header__title">Viewing <span>{ incomeCount }</span> { incomeWord } totalling <span>{ formattedIncomesTotal }</span></h1>
        <div className="page-header__actions">
          <Link className="button button--margin-right" to="/income/create">Add Income</Link>
          <Link className="button button--secondary" to="/expense/create">Add Expense</Link>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const visibleIncomes = selectIncomes(state.incomes, state.incomeFilters);
  return {
    incomeCount: visibleIncomes.length,
    incomesTotal: selectIncomesTotal(visibleIncomes)
  };
};

export default connect(mapStateToProps)(IncomesSummary);