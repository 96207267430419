import axios from 'axios';
import { cleanUpAndRedirect } from './auth';

export const getUserInfo = (user) => ({
  type: 'GET_USERINFO',
  ...user
});

export const startGetUserInfo = () => {
  return (dispatch, getState) => {
    return axios.get('/users/me', {
      headers: { 'x-auth': getState().auth.token }
    })
    .then((res) => {
      if (res.status === 200) {
        console.log('startGetUserInfo>', res.data.results);
        return dispatch(getUserInfo(res.data.results));
      }
    })
    .catch((err) => {
      console.log('startGetUserInfo failed', err);
      cleanUpAndRedirect(dispatch);
      throw err;
    });
  };
};

export const setUserInfo = (user) => ({
  type: 'SET_USERINFO',
  ...user
});

export const startSetUserInfo = ({ expenseType, paymentType, incomeType } = {}) => {
  return (dispatch, getState) => {
    return axios.put('/users/me', {
      expenseType,
      paymentType,
      incomeType
    }, {
      headers: { 'x-auth': getState().auth.token }
    })
    .then((res) => {
      if (res.status === 200) {
        console.log('startSetUserInfo>', res.data.results);
        return dispatch(setUserInfo(res.data.results));
      }
    })
    .catch((err) => {
      console.log('startSetUserInfo failed', err);
      throw err;
    });
  };
};
