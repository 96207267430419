import React from 'react';
import IncomeForm from './IncomeForm';
import { connect } from 'react-redux';
import { startAddIncome } from '../../actions/incomes';

export class AddIncomePage extends React.Component {
  onSubmit = (income) => {
    this.props.startAddIncome(income);
    this.props.history.push('/income/dashboard');
  };

  render() {
    return (
      <div>
        <div className="page-header">
          <div className="content-container">
            <h1 className="page-header__title">Add Income</h1>
          </div>
        </div>
        <div className="content-container">
          <IncomeForm
          onSubmit={this.onSubmit}
          />
        </div>
      </div>
    )
  };
};

const mapDispatchToProps = (dispatch) => ({
  startAddIncome: (expense) => dispatch(startAddIncome(expense))
});

export default connect(undefined, mapDispatchToProps)(AddIncomePage);