import { login, logout } from '../actions/auth';
import { startGetUserInfo } from '../actions/user';

const getCredentials = () => ({
  token: localStorage.getItem('thrifti.token'),
  email: localStorage.getItem('thrifti.email')
});

export const setCredentials = ({ token, email } = {}) => {
  if (!token && !email) {
    localStorage.removeItem('thrifti.token');
    localStorage.removeItem('thrifti.email');  
  }
  else {
    localStorage.setItem('thrifti.token', token);
    localStorage.setItem('thrifti.email', email);
  }
};

export const init = (store, callback) => {
  const { token, email } = getCredentials();

  // token found, try to validate it
  if (token && email) {
    console.log('init> token found, try validating it...');
    store.dispatch(login(token, email));
    store.dispatch(startGetUserInfo()).then(() => {
      console.log('init> token valid, proceed...')
      callback({ token, email });
    })
    .catch((e) => {
      console.log('init> startGetUserInfo failed:', e);
      store.dispatch(logout);
      setCredentials();
      callback();
    });
  }
  // not found, not login
  else {
    callback();
  }  
};